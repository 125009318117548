import { Component, Input, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GET_PLACE_GEOLOCATION } from 'src/app/graphql';
import { PlaceGeolocation } from 'src/types/Place';

@Component({
  selector: 'location-buttons',
  templateUrl: './location-buttons.component.html',
  styleUrls: ['./location-buttons.component.scss'],
})
export class LocationButtonsComponent implements OnInit {
  @Input() placeId?: number;
  @Input() coords?: { lat: number; lng: number };

  isLoading: boolean = true;
  googleMapsUrl: string = '';
  wazeUrl: string = '';

  constructor(private apollo: Apollo) {}

  ngOnInit(): void {
    if (this.coords) {
      this.generateUrls(this.coords.lat, this.coords.lng);
      this.isLoading = false;
    } else if (this.placeId) {
      this.apollo
        .watchQuery<PlaceGeolocation>({
          query: GET_PLACE_GEOLOCATION,
          variables: {
            where: {
              Id: this.placeId,
            },
          },
        })
        .valueChanges.subscribe((placeGeolocation) => {
          const { Lat: lat, Lng: lng } = placeGeolocation.data.getPlace;
          this.generateUrls(lat, lng);
          this.isLoading = placeGeolocation.loading;
        });
    }
  }

  generateUrls(lat: number, lng: number) {
    this.googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
    this.wazeUrl = `https://waze.com/ul?ll=${lat},${lng}&z=10`;
  }
}
