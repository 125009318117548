import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo, QueryRef } from 'apollo-angular';
import { GET_PLACE_DETAILS, getPlaceDetailsVariables } from 'src/app/graphql';
import {
  GetPlaceDetailsResponse,
  GetPlaceDetailsVariables,
  PlaceDetails,
} from 'src/types/Place';
import {
  query,
  stagger,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import { fadeAnimation } from 'src/app/animations';
import { LoadingService } from 'src/app/loading.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss'],
  animations: [
    trigger('fadeInPage', [
      transition(':enter', [
        query('h1,h2,div,section', [
          useAnimation(fadeAnimation, {
            params: { start: 0, end: 0 },
          }),
          stagger(30, [
            useAnimation(fadeAnimation, {
              params: { time: '300ms 100ms', start: 0, end: 1 },
            }),
          ]),
        ]),
      ]),
    ]),
    trigger('fadeInCards', [
      transition(':enter', [
        useAnimation(fadeAnimation, {
          params: { time: '300ms 200ms', start: 0, end: 1 },
        }),
      ]),
    ]),
  ],
})
export class CompanyDetailComponent implements OnInit, OnDestroy {
  currentRouteId: string = '';

  placeSubscription!: Subscription;
  isLoading: boolean = true;
  place: PlaceDetails = <PlaceDetails>{};

  constructor(
    private loadingService: LoadingService,
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute
  ) {
    this.loadingService.setLoading(true);
    this.activatedRoute.params.subscribe((params) => {
      this.currentRouteId = params['placeId'];
    });
  }

  ngOnInit(): void {
    this.loadingService
      .loadingListener()
      .subscribe((isLoading) => (this.isLoading = isLoading));

    if (this.currentRouteId) {
      this.placeSubscription = this.apollo
        .watchQuery<GetPlaceDetailsResponse, GetPlaceDetailsVariables>({
          query: GET_PLACE_DETAILS,
          variables: getPlaceDetailsVariables(+this.currentRouteId),
        })
        .valueChanges.subscribe((placeData) => {
          if (placeData.data.getPlace.Id) {
            this.place = placeData.data.getPlace;
            this.loadingService.setLoading(placeData.loading);
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.placeSubscription.unsubscribe();
  }
}
