import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { fadeAnimation } from 'src/app/animations';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { GIVE_FEEDBACK_TO_APP } from 'src/app/graphql';
import { LoadingService } from 'src/app/loading.service';
import {
  GiveFeedbacktoAppBody,
  GiveFeedbacktoAppResponse,
} from 'src/types/Feedback';

@Component({
  selector: 'feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeAnimation, {
          params: { time: '300ms 200ms', start: 0, end: 1 },
        }),
      ]),
    ]),
  ],
})
export class FeedbackComponent implements OnInit {
  isLoading: boolean = false;
  commentControl = new FormControl('');
  ratingScore: number = 0;

  constructor(
    private loadingService: LoadingService,
    private apollo: Apollo,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.loadingService
      .loadingListener()
      .subscribe((isLoading) => (this.isLoading = isLoading));
  }

  onRated(rating: number) {
    this.ratingScore = rating;
  }

  onSubmit() {
    if (this.ratingScore === 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: {
          warning: true,
          message:
            '¡Debe calificar su experiencia antes de enviar su evaluación!',
        },
        duration: 6000,
        panelClass: ['warning-snackbar'],
        horizontalPosition: 'end',
        verticalPosition: 'top',
      });
      return;
    }
    this.loadingService.setLoading(true);
    this.apollo
      .mutate<GiveFeedbacktoAppResponse, GiveFeedbacktoAppBody>({
        mutation: GIVE_FEEDBACK_TO_APP,
        variables: {
          body: {
            description: this.commentControl.value!,
            rate: this.ratingScore,
          },
        },
      })
      .subscribe((appFeedbackResponse) => {
        if (appFeedbackResponse.data?.saveUserFeedbackByUser.success) {
          this.loadingService.setLoading(appFeedbackResponse.loading!);
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: {
              validation: true,
              message: 'Se ha registrado su calificación correctamente.',
            },
            duration: 3000,
            panelClass: ['success-snackbar'],
            horizontalPosition: 'end',
            verticalPosition: 'top',
          });
          this.router.navigate(['/'], {
            replaceUrl: true,
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: {
              message:
                '¡No se ha podido registrar correctamente su calificación! Intente nuevamente.',
            },
            duration: 6000,
            panelClass: ['error-snackbar'],
            horizontalPosition: 'end',
            verticalPosition: 'top',
          });
          this.loadingService.setLoading(false);
        }
      });
  }
}
