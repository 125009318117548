import { Component, OnInit } from '@angular/core';
import {
  query,
  stagger,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import { fadeAnimation } from 'src/app/animations';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../../Auth/ChangePasswordPage/change-password/change-password.component';
import { Apollo, QueryRef } from 'apollo-angular';
import {
  Storage,
  ref,
  uploadBytes,
  deleteObject,
  UploadResult,
  StorageReference,
} from '@angular/fire/storage';
import {
  GET_USER,
  UPDATE_USER_MUTATION,
  updateUserVariables,
} from 'src/app/graphql';
import { UpdateUserResponse, User, UserResponse } from 'src/types/User';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { ErrorStateMatcher } from 'src/types/ErrorSateMatcher';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { alphaPattern } from 'src/types/regex-pattern';
import { emptyValidator } from '../../Auth/RegisterPage/client-registration/client-registration.component';
import { AuthenticationService } from 'src/app/authentication.service';
import { LoadingService } from 'src/app/loading.service';

interface SettingsFormGroup {
  firstName: FormControl;
  lastName: FormControl;
  phoneNumber: FormControl;
  email: FormControl;
}

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [
    trigger('fadeInPage', [
      transition(':enter', [
        query('h2,img,form,button,svg', [
          useAnimation(fadeAnimation, {
            params: { start: 0, end: 0 },
          }),
          stagger(30, [
            useAnimation(fadeAnimation, {
              params: { time: '300ms 100ms', start: 0, end: 1 },
            }),
          ]),
        ]),
      ]),
    ]),
  ],
})
export class ProfileComponent implements OnInit {
  getUserQuery!: QueryRef<UserResponse>;
  newProfilePictureFile: File | undefined;
  isLoading: boolean = true;
  isEmailAndPasswordProvider: boolean = false;

  matcher = new ErrorStateMatcher();
  settingsForm: FormGroup<SettingsFormGroup> = new FormGroup({
    firstName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(alphaPattern),
      emptyValidator(),
    ]),
    lastName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(alphaPattern),
      emptyValidator(),
    ]),
    phoneNumber: new FormControl<string>(''),
    email: new FormControl<string>({ value: '', disabled: true }),
  });

  constructor(
    private authenticationService: AuthenticationService,
    private loadingService: LoadingService,
    private apollo: Apollo,
    private storage: Storage,
    public dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  getNameErrorMessage(
    control: FormControl,
    controlDisplayName: string
  ): string {
    if (control.hasError('required') || control.hasError('isEmpty')) {
      return `${controlDisplayName} <strong>requerido</strong>`;
    }
    if (control.hasError('pattern')) {
      return 'Ingrese únicamente <strong>letras</strong>';
    }
    return '';
  }

  getPhoneNumberErrorMessage(): string {
    if (
      this.settingsForm.controls.phoneNumber.hasError('validatePhoneNumber')
    ) {
      return 'Ingrese un número telefónico <strong>válido</strong>';
    }
    return '';
  }

  ngOnInit(): void {
    this.loadingService.setLoading(true);

    this.loadingService
      .loadingListener()
      .subscribe((isLoading) => (this.isLoading = isLoading));
    /* Checks if user signed-in is linked with email and password provider, 
    it does not matter if it is linked with google provider as well.*/
    this.authenticationService.getAuthProvider().subscribe((userProviders) => {
      userProviders.map((provider) => {
        // If email and password provider is found within the array of providers, then show button to update password.
        if (provider.providerId === 'password') {
          this.isEmailAndPasswordProvider = true;
        }
      });
    });

    this.getUserQuery = this.apollo.watchQuery<UserResponse>({
      query: GET_USER,
      fetchPolicy: 'network-only',
    });
    this.fetchUserProfile();
  }

  fetchUserProfile() {
    this.getUserQuery.valueChanges.subscribe(({ data, loading }) => {
      if (data.getUser.success) {
        const userData = data.getUser.data;
        this.settingsForm.setValue({
          firstName: userData.FirstName,
          lastName: userData.LastName,
          phoneNumber: userData.PhoneNumber,
          email: userData.Email,
        });
        if (userData.FirstName.length > 0 && userData.FirstName.length > 0) {
          this.authenticationService.userProfile.next({
            ...userData,
            DisplayName: `${userData.FirstName} ${userData.LastName}`,
          });
        }
      }
      this.loadingService.setLoading(loading);
    });
  }

  onSelectingNewProfilePicture(newProfilePictureFile: File) {
    this.newProfilePictureFile = newProfilePictureFile;
  }

  onSubmit(previousUserData: User) {
    if (
      (this.settingsForm.valid &&
        this.settingsForm.touched &&
        this.settingsForm.dirty) ||
      !!this.newProfilePictureFile
    ) {
      this.loadingService.setLoading(true);
      this.handleFileToFirebaseUpload(previousUserData);
      this.getUserQuery.resetLastResults();
    } else {
      this.snackbar.openFromComponent(SnackbarComponent, {
        data: {
          validation: false,
          warning: true,
          message:
            '¡Se necesitan cambios válidos en el formulario para poder guardar!',
        },
        duration: 3000,
        panelClass: ['warning-snackbar'],
        horizontalPosition: 'end',
        verticalPosition: 'top',
      });
    }
  }

  handleFileToFirebaseUpload(previousUserData: User) {
    const previousPictureUrl: string | undefined =
      previousUserData.ProfilePictureUrl;
    // If there is not a new file, then function will just update user without the profilePictureUrl parameter
    if (this.newProfilePictureFile) {
      const imageName = this.newProfilePictureFile.type.replace('/', '.');
      const imageRefPath = `${new Date().getTime()}_${imageName}`;
      const newPictureRef = ref(
        this.storage,
        `Profile Pictures/${imageRefPath}`
      );

      if (previousPictureUrl) {
        const previousPictureName = previousPictureUrl.slice(
          previousPictureUrl.indexOf('%2F') + 3,
          previousPictureUrl.indexOf('?alt=media')
        );
        const previousPictureRef = ref(
          this.storage,
          `Profile Pictures/${previousPictureName}`
        );

        uploadBytes(newPictureRef, this.newProfilePictureFile)
          .then((uploadedImage) => {
            this.handleUserUpdate(
              uploadedImage,
              previousUserData,
              previousPictureRef
            );
          })
          .catch(() => {
            this.notifyErrorOnCatching();
          });
      } else {
        uploadBytes(newPictureRef, this.newProfilePictureFile)
          .then((uploadedImage) => {
            this.handleUserUpdate(uploadedImage, previousUserData);
          })
          .catch(() => {
            this.notifyErrorOnCatching();
          });
      }
    } else {
      this.updateUser(
        updateUserVariables({
          id: previousUserData.Id,
          firstName: this.settingsForm.controls.firstName.value,
          lastName: this.settingsForm.controls.lastName.value,
          phoneNumber: this.settingsForm.controls.phoneNumber.value,
        })
      ).subscribe(() => {
        this.notifyOnUserUpdated();
      });
    }
  }

  handleUserUpdate(
    uploadedImage: UploadResult,
    previousUserData: User,
    previousPictureRef?: StorageReference
  ) {
    const imageUrl = `https://firebasestorage.googleapis.com/v0/b/${uploadedImage.ref.bucket}/o/Profile%20Pictures%2F${uploadedImage.ref.name}?alt=media`;

    this.updateUser(
      updateUserVariables({
        id: previousUserData.Id,
        firstName: this.settingsForm.controls.firstName.value,
        lastName: this.settingsForm.controls.lastName.value,
        phoneNumber: this.settingsForm.controls.phoneNumber.value,
        profilePictureUrl: imageUrl,
      })
    ).subscribe(() => {
      if (previousPictureRef) {
        deleteObject(previousPictureRef)
          .then()
          .finally(() => {
            this.notifyOnUserUpdated();
          });
      } else {
        this.notifyOnUserUpdated();
      }
    });
  }

  updateUser(updateUserVariables: any) {
    return this.apollo.mutate<UpdateUserResponse>({
      mutation: UPDATE_USER_MUTATION,
      variables: updateUserVariables,
    });
  }

  notifyErrorOnCatching() {
    this.loadingService.setLoading(false);
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: {
        validation: false,
        warning: false,
        message:
          '¡Hubo un problema con el archivo adjunto! No se pudo completar la operación.',
      },
      duration: 2000,
      panelClass: ['error-snackbar'],
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

  notifyOnUserUpdated() {
    this.snackbar
      .openFromComponent(SnackbarComponent, {
        data: {
          validation: true,
          warning: false,
          message: '¡Datos actualizados exitosamente!',
        },
        duration: 1000,
        panelClass: ['success-snackbar'],
        horizontalPosition: 'end',
        verticalPosition: 'top',
      })
      .afterDismissed()
      .subscribe(() => {
        this.getUserQuery.refetch().then(() => {
          this.newProfilePictureFile = undefined;
          this.settingsForm.markAsUntouched();
          this.settingsForm.markAsPristine();
          this.getUserQuery.resetLastResults();
          this.loadingService.setLoading(false);
        });
      });
  }

  openDialog(event: MouseEvent): void {
    this.dialog.open(ChangePasswordComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
    });
  }

  onDeleteAccount() {
    this.authenticationService.deactivateAccount();
  }
}
