export const environment = {
  production: false,
  firebase: {
    projectId: 'citas-af133',
    appId: '1:836755194624:web:e810fa279adf7113fda9e5',
    storageBucket: 'citas-af133.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyCrAygNg4tMzG_IkErIgPgV8mO8hqxchwo',
    authDomain: 'citas-af133.firebaseapp.com',
    messagingSenderId: '836755194624',
    measurementId: 'G-RDJQ6F7PWL',
  },
  apolloServer: 'https://api.tavuel.com/appointments',
  apolloWsServer: 'wss://api.tavuel.com/appointments',
  adminApplicationBaseUrl: 'https://admin-dev.agenda.cr',
};
