import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { filter, map } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';

export const authenticationGuard: CanActivateFn = (_, state) => {
  const authenticationService: AuthenticationService = inject(
    AuthenticationService
  );
  const router: Router = inject(Router);
  const snackBar: MatSnackBar = inject(MatSnackBar);

  return authenticationService.isAuthenticated().pipe(
    filter((user) => user !== undefined),
    map((user) => {
      if (user === null) {
        snackBar.openFromComponent(SnackbarComponent, {
          data: {
            warning: true,
            message: '¡Debe iniciar sesión para continuar!',
          },
          duration: 5000,
          panelClass: ['warning-snackbar'],
          horizontalPosition: 'end',
          verticalPosition: 'top',
        });
        return router.createUrlTree(['/sign-in'], {
          queryParams: {
            redirectTo: state.url,
          },
        });
      }
      return true;
    })
  );
};
