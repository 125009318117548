import { gql } from 'apollo-angular';

export const GET_PLACES = gql`
  query GetPlaces(
    $distance: Int!
    $lng: Float!
    $lat: Float!
    $name: String!
    $skip: Int!
    $take: Int!
    $categories: [Int!]!
  ) {
    getPlaces(
      distance: $distance
      lng: $lng
      lat: $lat
      name: $name
      skip: $skip
      take: $take
      categories: $categories
    ) {
      data {
        id
        name
        address
        description
        category
        profilePictureUrl
        lat
        lng
        rate
        specialties {
          Name
          Color
        }
      }
    }
  }
`;

export const getPlacesVariables = {
  distance: 3000,
  lng: 0,
  lat: 0,
  name: '',
  skip: 0,
  take: 10,
  categories: [] as number[],
};

export const GET_PLACE_DETAILS = gql`
  query GetPlace(
    $where: PlaceWhereUniqueInput!
    $specialtiesWhere: SpecialtyWhereInput
  ) {
    getPlace(where: $where) {
      Id
      Name
      Email
      PhoneNumber
      Description
      Address
      Lat
      Lng
      ProfilePictureUrl
      Specialties(where: $specialtiesWhere) {
        Id
        Color
        Name
        Description
        Price
        ProfessionalName
        Schedule {
          Name
        }
        SpecialtyFiles {
          Id
          Name
          Url
        }
      }
    }
  }
`;

export const getPlaceDetailsVariables = (placeId: number) => ({
  where: {
    Id: placeId,
    SystemCode: {
      is: {
        Entity: {
          equals: 'Place',
        },
        Value: {
          equals: 'Active',
        },
      },
    },
  },
  specialtiesWhere: {
    SystemCode: {
      is: {
        Entity: {
          equals: 'Specialty',
        },
        Value: {
          equals: 'Active',
        },
      },
    },
  },
});

export const GET_PLACE_GEOLOCATION = gql`
  query GetPlaceGeoLocation($where: PlaceWhereUniqueInput!) {
    getPlace(where: $where) {
      Lat
      Lng
    }
  }
`;

export const GET_PLACE_CATEGORIES = gql`
  query PlaceCategories {
    placeCategories {
      Id
      Name
    }
  }
`;
