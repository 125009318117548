import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Apollo, QueryRef } from 'apollo-angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  GetSchedulesAvailableBySpecialtyResponse,
  GetSpecialtiesByPlaceResponse,
  Specialty,
} from 'src/types/Specialty';
import { ScheduleAppointmentModalComponent } from 'src/app/components/schedule-time-slot-selector/schedule-appointment-modal/schedule-appointment-modal.component';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import {
  RescheduleAppointmentResponse,
  ScheduleAppointmentResponse,
} from 'src/types/Appointment';
import {
  SCHEDULE_APPOINTMENT,
  SCHEDULE_APPOINTMENT_BODY,
  GET_SPECIALTIES_BY_PLACE,
  getSpecialtiesByPlaceVariables,
  GET_SCHEDULES_AVAILABLE_BY_SPECIALTY,
  getSchedulesAvailableBySpecialtyVariables,
  RESCHEDULE_APPOINTMENT,
  RESCHEDULE_APPOINTMENT_BODY,
} from 'src/app/graphql';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SCHEDULE_SLOTS_NOTIFICATION } from './graphql/Schedule';
import { ScheduleSlotsNotificationResponse } from 'src/types/Schedule';
import { LoadingService } from './loading.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsService {
  placeId!: number;
  selectedSpecialtyIdByParam: number | null = null;
  appointmentIdByParam: number | null = null;
  currentSpecialty = new BehaviorSubject<Specialty | null>(null);
  dateSelected: string | null = null;
  dateSelectedListener = new BehaviorSubject<string | null>(this.dateSelected);
  selectedHour: string | null = null;
  selectedHourListener = new BehaviorSubject<string | null>(this.dateSelected);
  isLoading: boolean = true;
  isButtonLoading: boolean = false;
  isAuthenticated: boolean = false;
  isPendingAppointmentToSchedule: boolean = false;
  authSubscription = new Subscription();

  schedulesAvailableBySpecialtyQueryRef!: QueryRef<
    GetSchedulesAvailableBySpecialtyResponse,
    any
  >;

  constructor(
    private authenticationService: AuthenticationService,
    private loadingService: LoadingService,
    private apollo: Apollo,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackbar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.authSubscription = this.authenticationService
      .isAuthenticated()
      .subscribe((user) => (this.isAuthenticated = !!user));

    this.loadingService
      .loadingListener()
      .subscribe((isLoading) => (this.isLoading = isLoading));
  }

  specialtiesQuery() {
    return this.apollo.watchQuery<GetSpecialtiesByPlaceResponse>({
      query: GET_SPECIALTIES_BY_PLACE,
      variables: getSpecialtiesByPlaceVariables(+this.placeId),
      fetchPolicy: 'network-only',
    });
  }

  schedulesAvailableBySpecialtyQuery(dateSelected: string) {
    this.schedulesAvailableBySpecialtyQueryRef =
      this.apollo.watchQuery<GetSchedulesAvailableBySpecialtyResponse>({
        query: GET_SCHEDULES_AVAILABLE_BY_SPECIALTY,
        variables: getSchedulesAvailableBySpecialtyVariables(
          dateSelected,
          this.selectedSpecialtyIdByParam!
        ),
        fetchPolicy: 'network-only',
      });
    return this.schedulesAvailableBySpecialtyQueryRef;
  }

  subscribeToMoreSchedulesAvailableBySpecialty() {
    this.schedulesAvailableBySpecialtyQueryRef!.subscribeToMore<ScheduleSlotsNotificationResponse>(
      {
        document: SCHEDULE_SLOTS_NOTIFICATION,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          }

          const newTimeSlots =
            subscriptionData.data.onNotificationSchedules.data;

          return {
            getSchedulesAvailableBySpecialty: {
              ...prev.getSchedulesAvailableBySpecialty,
              data: newTimeSlots,
            },
          };
        },
      }
    );
  }

  manageAppointmentScheduling() {
    if (this.dateSelected && this.selectedHour) {
      // Before trying to schedule, check if the selected time slot hasn't expired:
      if (this.isSelectedTimeValid()) {
        this.isButtonLoading = true;
        // If an Appointment Id is present in the route, reschedule the appointment:
        if (this.appointmentIdByParam) {
          this.rescheduleAppointment();
        } else {
          this.scheduleAppointment();
        }
      } else {
        this.isButtonLoading = false;
        this.snackbar.openFromComponent(SnackbarComponent, {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          data: {
            warning: true,
            message: '¡La hora seleccionada previamente ya no es válida!',
          },
          duration: 2000,
          panelClass: ['warning-snackbar'],
        });
      }
    }
  }

  scheduleAppointment() {
    if (this.isAuthenticated) {
      // If a user is authenticated and there is a pending appointment to schedule,
      // then change the pending state and then continue with the appointment scheduling process:
      if (this.isPendingAppointmentToSchedule) {
        this.isPendingAppointmentToSchedule = false;
      }

      this.apollo
        .mutate<ScheduleAppointmentResponse>({
          mutation: SCHEDULE_APPOINTMENT,
          variables: SCHEDULE_APPOINTMENT_BODY(
            this.dateSelected!,
            this.selectedHour!,
            this.selectedSpecialtyIdByParam!
          ),
        })
        .subscribe({
          next: (scheduleAppointmentResponse) => {
            const appointmentId = scheduleAppointmentResponse.data
              ?.addAppointmentClient.success
              ? scheduleAppointmentResponse.data.addAppointmentClient.data.Id
              : 0;
            this.notifyAppointmentStatus(
              scheduleAppointmentResponse.data!.addAppointmentClient.success,
              scheduleAppointmentResponse.data!.addAppointmentClient.message,
              appointmentId
            );
          },
          error: (error) => {
            this.notifyAppointmentStatus(
              false,
              '¡Ha ocurrido un error inesperado! No se ha podido agendar la cita.',
              0
            );
          },
        });
    } else {
      // If a user is not authenticated, then navigate to authenticate and
      // come back afterwards using the redirectTo query param:
      this.isPendingAppointmentToSchedule = true;
      const redirectToPendingScheduling = `${this.router.url}&pending-appointment=true`;
      this.router.navigate(['/sign-in'], {
        queryParams: {
          redirectTo: redirectToPendingScheduling,
        },
      });
    }
  }

  rescheduleAppointment() {
    this.apollo
      .mutate<RescheduleAppointmentResponse>({
        mutation: RESCHEDULE_APPOINTMENT,
        variables: RESCHEDULE_APPOINTMENT_BODY(
          this.appointmentIdByParam!,
          this.dateSelected!,
          this.selectedHour!,
          this.selectedSpecialtyIdByParam!
        ),
      })
      .subscribe({
        next: (rescheduleAppointmentResponse) => {
          const appointmentId = rescheduleAppointmentResponse.data
            ?.editAppointmentClient.success
            ? rescheduleAppointmentResponse.data.editAppointmentClient.data.Id
            : 0;
          this.notifyAppointmentStatus(
            rescheduleAppointmentResponse.data!.editAppointmentClient.success,
            rescheduleAppointmentResponse.data!.editAppointmentClient.message,
            appointmentId
          );
        },
        error: (error) => {
          this.notifyAppointmentStatus(
            false,
            '¡Ha ocurrido un error inesperado! No se ha podido reagendar la cita.',
            0
          );
        },
      });
  }

  notifyAppointmentStatus(
    isSuccessful: boolean,
    message: string,
    appointmentId: number
  ) {
    if (isSuccessful) {
      this.isButtonLoading = false;
      this.dialog
        .open(ScheduleAppointmentModalComponent)
        .afterClosed()
        .subscribe((action) => {
          this.loadingService.setLoading(true);
          this.resetUIToInitialState();
          if (action && action.shouldNavigateToAppointments) {
            this.router.navigate(['/appointments'], {
              replaceUrl: true,
              queryParams: { appointment: appointmentId, status: 'pending' },
            });
          } else {
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.router.navigate(['/schedule-appointment', this.placeId], {
                  replaceUrl: true,
                  queryParams: { specialty: this.selectedSpecialtyIdByParam },
                });
              });
          }
          setTimeout(() => {
            this.loadingService.setLoading(false);
          }, 300);
        });
    } else {
      this.isButtonLoading = false;
      this.snackbar.openFromComponent(SnackbarComponent, {
        horizontalPosition: 'end',
        verticalPosition: 'top',
        data: {
          warning: false,
          validation: false,
          message,
        },
        duration: 6000,
        panelClass: ['error-snackbar'],
      });
    }
  }

  resetUIToInitialState() {
    if (this.isAuthenticated && !this.isPendingAppointmentToSchedule) {
      this.onDateSelected(null);
      this.onTimeSlotSelected(null);
      this.currentSpecialty.next(null);
    }
  }

  isSelectedTimeValid() {
    const now = new Date(Date.now());

    const formattedCurrentDate = now.toLocaleString('es-ES', {
      dateStyle: 'short',
      timeZone: 'UTC',
    });
    const formattedDateSelected = new Date(this.dateSelected!).toLocaleString(
      'es-ES',
      {
        dateStyle: 'short',
        timeZone: 'UTC',
      }
    );

    if (formattedDateSelected === formattedCurrentDate) {
      const [hours, minutes] = this.selectedHour!.split(':');

      const exactSelectedHourDate = new Date(now);
      exactSelectedHourDate.setHours(+hours);
      exactSelectedHourDate.setMinutes(+minutes);

      // Check if the hour previously selected is not valid anymore:
      if (exactSelectedHourDate.getTime() <= now.getTime()) {
        return false;
      }
    }
    return true;
  }

  onDateSelected(dateSelected: string | null) {
    this.dateSelected = dateSelected;
    this.dateSelectedListener.next(this.dateSelected);
  }

  onTimeSlotSelected(hour: string | null) {
    this.selectedHour = hour;
    this.selectedHourListener.next(this.selectedHour);
  }
}
