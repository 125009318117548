import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeAnimation } from 'src/app/animations';
import {
  AppointmentsHistory,
  AppointmentsHistoryResponse,
  AppointmentsHistoryVariables,
} from 'src/types/Appointment';
import { Apollo, QueryRef } from 'apollo-angular';
import { Subscription } from 'rxjs';
import {
  APPOINTMENTS_HISTORY,
  GET_USER,
  appointmentsHistoryGetByStatus,
  appointmentsHistoryInitialVariables,
} from 'src/app/graphql';
import { AuthenticationService } from 'src/app/authentication.service';
import { UserResponse } from 'src/types/User';
import { LoadingService } from 'src/app/loading.service';

@Component({
  selector: 'appointment-history',
  templateUrl: './appointment-history.component.html',
  styleUrls: ['./appointment-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeAnimation, {
          params: { time: '300ms 200ms', start: 0, end: 1 },
        }),
      ]),
    ]),
  ],
})
export class AppointmentHistoryComponent implements OnInit {
  appointmentsQuery?: QueryRef<
    AppointmentsHistoryResponse,
    AppointmentsHistoryVariables
  >;
  appointmentsSubscription?: Subscription;
  authSubscription?: Subscription;
  getUserSubscription?: Subscription;
  appointmentsHistory: AppointmentsHistory[] = [];
  private userId!: number;
  isLoading: boolean = true;
  readonly PAGE_SIZE: number = 20;
  pageNumber: number = 1;
  nextIsEnabled: boolean = true;
  selectedAppointmentStatus = { label: 'Todas', value: 'All' };
  appointmentStatus: { label: string; value: string }[] = [
    { label: 'Todas', value: 'All' },
    { label: 'Aprobadas', value: 'Approved' },
    { label: 'Pendientes', value: 'Pending' },
    { label: 'Rechazadas', value: 'Rejected' },
  ];

  constructor(
    private apollo: Apollo,
    private authenticationService: AuthenticationService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.setLoading(true);
    this.loadingService
      .loadingListener()
      .subscribe((isLoading) => (this.isLoading = isLoading));
    this.authSubscription = this.authenticationService
      .isAuthenticated()
      .subscribe((user) => {
        if (!!user) {
          this.getUserSubscription = this.apollo
            .watchQuery<UserResponse>({
              query: GET_USER,
              fetchPolicy: 'network-only',
            })
            .valueChanges.subscribe((userData) => {
              if (userData.data.getUser.success) {
                this.userId = userData.data.getUser.data.Id;
                this.appointmentsQuery = this.apollo.watchQuery<
                  AppointmentsHistoryResponse,
                  AppointmentsHistoryVariables
                >({
                  query: APPOINTMENTS_HISTORY,
                  variables: {
                    ...appointmentsHistoryInitialVariables(this.userId),
                    take: this.PAGE_SIZE,
                  },
                  fetchPolicy: 'network-only',
                });

                this.subscribeToappointmentsQuery();
              }
            });
        }
      });
  }

  subscribeToappointmentsQuery() {
    this.appointmentsSubscription =
      this.appointmentsQuery?.valueChanges.subscribe(({ data, loading }) => {
        this.appointmentsHistory = data.appointments;
        if (
          this.appointmentsHistory.length === 0 ||
          this.appointmentsHistory.length < this.PAGE_SIZE
        ) {
          this.nextIsEnabled = false;
        }
        this.loadingService.setLoading(loading);
      });
  }

  fetchMore() {
    this.appointmentsQuery
      ?.fetchMore({
        variables: { skip: (this.pageNumber - 1) * this.PAGE_SIZE },
      })
      .then(({ data }) => {
        if (data.appointments.length === 0) {
          this.nextIsEnabled = false;
        } else {
          this.nextIsEnabled = true;
          this.appointmentsHistory = data.appointments;
          if (this.appointmentsHistory.length < this.PAGE_SIZE) {
            this.nextIsEnabled = false;
          }
        }
      });
  }

  refetch() {
    // Reset paginator values:
    this.pageNumber = 1;
    this.nextIsEnabled = true;

    let refetchVariables = {
      ...appointmentsHistoryInitialVariables(this.userId),
      take: this.PAGE_SIZE,
      skip: (this.pageNumber - 1) * this.PAGE_SIZE,
    };

    if (this.selectedAppointmentStatus.value !== 'All') {
      refetchVariables = {
        ...refetchVariables,
        where: {
          ...refetchVariables.where,
          ...appointmentsHistoryGetByStatus(
            this.selectedAppointmentStatus.value
          ),
        },
      };
    }

    this.appointmentsQuery?.refetch(refetchVariables).finally(() => {
      this.appointmentsQuery?.resetLastResults();
    });
  }

  onFilteringStatus() {
    this.refetch();
  }

  onPrevious() {
    this.pageNumber = this.pageNumber - 1;
    this.fetchMore();
  }

  onNext() {
    this.pageNumber = this.pageNumber + 1;
    this.fetchMore();
  }

  ngOnDestroy(): void {
    this.appointmentsSubscription?.unsubscribe();
    this.authSubscription?.unsubscribe();
    this.getUserSubscription?.unsubscribe();
  }
}
