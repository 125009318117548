import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication.service';
import {
  Auth,
  signInWithPopup,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { emailPattern, passwordPattern } from 'src/types/regex-pattern';
import { ErrorStateMatcher } from 'src/types/ErrorSateMatcher';
import { Location } from '@angular/common';

interface LoginFormGroup {
  email: FormControl;
  password: FormControl;
}

@Component({
  selector: 'client-login',
  templateUrl: './client-login.component.html',
  styleUrls: ['./client-login.component.scss'],
})
export class ClientLoginComponent {
  private googleProvider = new GoogleAuthProvider();
  matcher = new ErrorStateMatcher();

  loginForm: FormGroup<LoginFormGroup> = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(emailPattern),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(passwordPattern),
      Validators.minLength(8),
    ]),
  });
  showPassword: boolean = false;
  isLoading: boolean = false;
  isGoogleLoading: boolean = false;

  constructor(
    private auth: Auth,
    private router: Router,
    private location: Location,
    private authenticationService: AuthenticationService
  ) {}

  getEmailErrorMessage() {
    if (this.loginForm.controls.email.hasError('required')) {
      return 'Correo electrónico <strong>requerido</strong>';
    }
    if (this.loginForm.controls.email.hasError('pattern')) {
      return 'Formato de correo electrónico <strong>inválido</strong>';
    }
    return '';
  }
  getPasswordErrorMessage() {
    if (this.loginForm.controls.password.hasError('required')) {
      return 'Contraseña <strong>requerida</strong>';
    }
    if (this.loginForm.controls.password.hasError('pattern')) {
      return '<strong>Utilice mayúsculas, minúsculas y al menos un número.</strong>';
    }
    if (this.loginForm.controls.password.hasError('minlength')) {
      return 'Debe tener <strong>al menos 8 caracteres.</strong>';
    }
    return '';
  }

  login() {
    if (this.loginForm.valid) {
      this.isLoading = true;
      signInWithEmailAndPassword(
        this.auth,
        this.loginForm.controls.email.value,
        this.loginForm.controls.password.value
      )
        .then(this.authenticationService.onFirebaseSignIn)
        .catch(this.authenticationService.handleFirebaseErrors)
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        });
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  loginWithGoogle() {
    this.isGoogleLoading = true;
    signInWithPopup(this.auth, this.googleProvider)
      .then(this.authenticationService.onFirebaseSignIn)
      .catch(this.authenticationService.handleFirebaseErrors)
      .finally(() => {
        setTimeout(() => {
          this.isGoogleLoading = false;
        }, 1000);
      });
  }

  navigateToRegister() {
    this.router.navigate(['/sign-up'], {
      queryParams: this.router.parseUrl(this.router.url).queryParams,
    });
  }

  goBack() {
    this.location.back();
  }
}
