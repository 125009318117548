import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, Subscription, debounceTime } from 'rxjs';

@Component({
  selector: 'search-companies',
  templateUrl: './search-companies.component.html',
  styleUrls: ['./search-companies.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchCompaniesComponent implements OnInit, OnDestroy {
  @Output() searchKeyword = new Subject<string>();
  @Output() userAgentLocation = new Subject<GeolocationCoordinates | null>();
  @Output() selectedCategories = new Subject<number[]>();
  searchBar = new FormControl('');
  showClearFilterButton: boolean = false;
  searchTypingSubscription = new Subscription();
  searchTyping = new Subject<string>();

  ngOnInit(): void {
    this.searchTypingSubscription = this.searchTyping
      .asObservable()
      .pipe(debounceTime(1000))
      .subscribe((keyword) => {
        this.performSearch(keyword);
      });
  }

  performSearch(keyword: string) {
    this.searchKeyword.next(keyword);
  }

  onTyping() {
    if (!this.searchBar.pristine) {
      this.searchTyping.next(this.searchBar.value!.trim());
    }
  }

  onSearchClick() {
    if (!this.searchBar.pristine) {
      this.performSearch(this.searchBar.value!.trim());
    }
  }

  onRelayUserAgentLocation(userAgentCoords: GeolocationCoordinates | null) {
    this.userAgentLocation.next(userAgentCoords);
  }

  onClearFilters() {
    this.searchTyping.next('');
    this.searchBar.reset('');
  }

  ngOnDestroy(): void {
    this.searchTypingSubscription.unsubscribe();
  }
}
