<section
  #appointmentCard
  class="appointment-info-card"
  highlightSelected="#561ad9"
  highlightSelectedProperty="outlineColor"
>
  <div>
    <h2 class="appointment-info-card-title">Detalles</h2>
    <div class="appointment-info-card-row">
      <h3>Lugar</h3>
      <p>{{ appointmentData.appointment.placeName }}</p>
      <p>
        {{ appointmentData.appointment.placeAddress }}
      </p>
    </div>
    <div class="appointment-info-card-row">
      <h3>Información Personal</h3>
      <p>{{ appointmentData.appointment.userName }}</p>
      <p class="email-text">{{ appointmentData.appointment.userEmail }}</p>
      <!-- API is sending a "null" string when the phone number is null, this will patch it for now: -->
      <p>
        {{
          appointmentData.appointment.userPhoneNumber === "null"
            ? ""
            : appointmentData.appointment.userPhoneNumber
        }}
      </p>
    </div>
    <div class="appointment-info-card-row">
      <h3>Tipo de Cita</h3>
      <p>{{ appointmentData.appointment.specialtyName }}</p>
      <h3>Fecha</h3>
      <p>{{ appointmentData.appointment.date | date : "dd/MM/YYYY" }}</p>
      <h3>Horario</h3>
      <p>
        {{
          appointmentData.appointment.date +
            " " +
            appointmentData.appointment.startTime | date : "hh:mm a"
        }}
      </p>
      <h3>Tiempo Estimado</h3>
      <p>
        {{ appointmentData.appointment.specialtyTime }}
        min
      </p>
      <h3>Estado</h3>
      <p
        [class]="
          'status-' + appointmentData.appointment.systemCode.toLowerCase()
        "
      >
        {{ generateStatusLabel(appointmentData.appointment.systemCode) }}
      </p>
    </div>
  </div>
  <div>
    <location-buttons
      [placeId]="appointmentData.appointment.placeId"
    ></location-buttons>
    <div
      class="appointment-info-card-buttons"
      *ngIf="appointmentData.appointment.systemCode !== 'Rejected'"
    >
      <button
        *ngIf="appointmentData.appointment.systemCode === 'Pending'"
        class="appointment-info-card-button"
        mat-raised-button
        color="accent"
        (click)="onRescheduleAppointment()"
      >
        Ir a reagendar
      </button>
      <button
        class="appointment-info-card-button"
        mat-raised-button
        color="primary"
        (click)="onCancelAppointment()"
      >
        Cancelar cita
      </button>
    </div>
  </div>
</section>
