import { Component, OnDestroy, OnInit } from '@angular/core';
import { PwaUpdateService } from './pwa-update.service';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdateModalComponent } from './components/sw-update-modal/sw-update-modal.component';
import { Subscription } from 'rxjs';
import { register } from 'swiper/element/bundle';
import { UserAgentService } from './user-agent.service';
import { UserAgentModalComponent } from './components/user-agent-modal/user-agent-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  newVersionAvailableSubscription!: Subscription;
  constructor(
    private pwaUpdateService: PwaUpdateService,
    private userAgentService: UserAgentService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.newVersionAvailableSubscription =
      this.pwaUpdateService.isNewVersionAvailable.subscribe(
        (isUpdateAvailable) => {
          if (isUpdateAvailable) {
            this.dialog
              .open(SwUpdateModalComponent)
              .afterClosed()
              .subscribe((action) => {
                if (action && action.shouldApplyUpdate) {
                  this.pwaUpdateService.applyUpdate();
                }
              });
          }
        }
      );

    if (this.shouldRecommendBrowser()) {
      this.dialog
        .open(UserAgentModalComponent)
        .afterClosed()
        .subscribe((action) => {
          if (action && action.shouldAcknowledge) {
            this.userAgentService.setUserAgentAcknowledgement();
          }
        });
    }

    // Register Swiper custom elements globally
    register();
  }

  shouldRecommendBrowser() {
    return this.userAgentService.shouldShowInAppBrowserAlert();
  }

  ngOnDestroy(): void {
    this.newVersionAvailableSubscription.unsubscribe();
  }
}
