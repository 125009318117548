import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { CookieService } from 'ngx-cookie-service';
import {
  Auth,
  signOut,
  onAuthStateChanged,
  User,
  UserCredential,
  confirmPasswordReset,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
} from '@angular/fire/auth';
import {
  CLIENT_LOGIN_MUTATION,
  CLIENT_PASSWORD_CHANGE_MUTATION,
  CLIENT_PASSWORD_RECOVERY_MUTATION,
  DEACTIVATE_ACCOUNT,
  REACTIVATE_ACCOUNT,
  REGISTER_CLIENT_MUTATION,
  REGISTER_CLIENT_WITH_GOOGLE_MUTATION,
} from './graphql';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { AuthenticationClientResponse } from 'src/types/Auth';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmAccountActivationComponent } from './components/confirm-account-activation/confirm-account-activation.component';
import {
  DeactivateAccountResponse,
  ReactivateAccountResponse,
  UserInfo,
} from 'src/types/User';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  /* As it is a Behavior Subject, The first value sent will be undefined, which is needed for the auth guard, 
  then it'll send either the user or null*/
  private currentUser = new BehaviorSubject<User | null | undefined>(undefined);
  // Profile user info used for the profile page and sidenav:
  userProfile = new BehaviorSubject<UserInfo | null>(null);

  constructor(
    private apollo: Apollo,
    private auth: Auth,
    private cookieService: CookieService,
    private router: Router,
    private snackbar: MatSnackBar,
    private dialog: MatDialog
  ) {
    onAuthStateChanged(this.auth, (user) => {
      this.currentUser.next(user);
    });
  }

  isAuthenticated() {
    // Exposes the user only as an observable, to prevent a next() method call outside the service
    return this.currentUser.asObservable();
  }

  getAuthProvider() {
    return this.currentUser.pipe(map((user) => user!.providerData));
  }

  registerClient(variables: any) {
    return this.apollo.mutate<AuthenticationClientResponse>({
      mutation: REGISTER_CLIENT_MUTATION,
      variables,
    });
  }

  registerClientWithGoogle(variables: any) {
    return this.apollo.mutate<AuthenticationClientResponse>({
      mutation: REGISTER_CLIENT_WITH_GOOGLE_MUTATION,
      variables,
    });
  }

  clientLogin() {
    return this.apollo.mutate<AuthenticationClientResponse>({
      mutation: CLIENT_LOGIN_MUTATION,
    });
  }

  clientLogout(shouldReload: boolean = false) {
    signOut(this.auth);
    this.clearTokenCookie();
    this.apollo.client.resetStore();

    if (shouldReload) window.location.reload();
  }

  recoverPassword(variables: any) {
    return this.apollo.mutate<AuthenticationClientResponse>({
      mutation: CLIENT_PASSWORD_RECOVERY_MUTATION,
      variables,
    });
  }

  verifyPasswordReset(mode: string, actionCode: string) {
    return verifyPasswordResetCode(this.auth, actionCode)
      .then((email) => {
        return {
          accountEmail: email,
          actionCode,
        };
      })
      .catch((error: any) => {
        switch (true) {
          case error.message.includes('expired-action-code'):
            this.passwordResetErrorNotification(
              'El enlace recibido ha expirado. ¡Intente restablecer contraseña nuevamente!'
            );
            break;
          case error.message.includes('invalid-action-code'):
            this.passwordResetErrorNotification(
              'El enlace recibido ya no es válido. ¡Intente restablecer contraseña nuevamente!'
            );
            break;
          default:
            this.passwordResetErrorNotification(
              'Ha ocurrido un error inesperado. ¡Intente restablecer contraseña nuevamente!'
            );
            break;
        }
      });
  }

  passwordResetErrorNotification(message: string) {
    this.snackbar
      .openFromComponent(SnackbarComponent, {
        data: {
          message,
        },
        duration: 3000,
        panelClass: ['error-snackbar'],
        horizontalPosition: 'end',
        verticalPosition: 'top',
      })
      .afterOpened()
      .subscribe(() => {
        this.router.navigate(['/restore-password'], { replaceUrl: true });
      });
  }

  handleResetPassword(
    actionCode: string,
    accountEmail: string,
    newPassword: string
  ) {
    return confirmPasswordReset(this.auth, actionCode, newPassword)
      .then(() => {
        this.snackbar.openFromComponent(SnackbarComponent, {
          data: {
            validation: true,
            message: '¡Contraseña restablecida satisfactoriamente!',
          },
          duration: 3000,
          panelClass: ['success-snackbar'],
          horizontalPosition: 'end',
          verticalPosition: 'top',
        });
        signInWithEmailAndPassword(this.auth, accountEmail, newPassword)
          .then(this.onFirebaseSignIn)
          .catch(this.handleFirebaseErrors);
      })
      .catch(() => {
        this.snackbar
          .openFromComponent(SnackbarComponent, {
            data: {
              message:
                'Ha ocurrido un error durante la confirmación de la nueva contraseña. ¡Intente de nuevo!',
            },
            duration: 6000,
            panelClass: ['error-snackbar'],
            horizontalPosition: 'end',
            verticalPosition: 'top',
          })
          .afterDismissed()
          .subscribe(() => {
            this.router.navigate(['/restore-password'], { replaceUrl: true });
          });
      });
  }

  changePassword(variables: any) {
    return this.apollo.mutate<AuthenticationClientResponse>({
      mutation: CLIENT_PASSWORD_CHANGE_MUTATION,
      variables,
    });
  }

  onFirebaseSignIn = async (firebaseResponse: UserCredential) => {
    const token = await firebaseResponse.user.getIdToken();
    this.setTokenCookie(token);

    this.clientLogin().subscribe((loginResponse) => {
      let isSuccessful = true;
      let snackbarOptions: MatSnackBarConfig = {
        horizontalPosition: 'end',
        verticalPosition: 'top',
        data: {
          validation: loginResponse.data?.login.success,
          message: loginResponse.data?.login.message,
        },
        duration: 2000,
        panelClass: ['success-snackbar'],
      };

      if (!loginResponse.data!.login.success) {
        if (
          loginResponse.data!.login.message.includes(
            'correo electrónico no se encuentra asociado a ninguna cuenta registrada'
          )
        ) {
          const registerVariables = {
            body: {
              token,
            },
          };
          this.registerClientWithGoogle(registerVariables).subscribe(
            (registerResponse) => {
              if (registerResponse.data!.registerClientWithGoogle.success) {
                this.snackbar
                  .openFromComponent(SnackbarComponent, {
                    data: {
                      validation:
                        registerResponse.data!.registerClientWithGoogle.success,
                      message:
                        registerResponse.data!.registerClientWithGoogle.message,
                    },
                    duration: 2000,
                    panelClass: ['success-snackbar'],
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                  })
                  .afterOpened()
                  .subscribe(() => {
                    let navigateTo = this.router.parseUrl(
                      this.router.routerState.snapshot.url
                    ).queryParams['redirectTo'];
                    this.router.navigateByUrl(navigateTo);
                  });
              } else {
                this.snackbar.openFromComponent(SnackbarComponent, {
                  data: {
                    validation: false,
                    warning: false,
                    message:
                      registerResponse.data!.registerClientWithGoogle.message,
                  },
                  duration: 2000,
                  panelClass: ['error-snackbar'],
                  horizontalPosition: 'end',
                  verticalPosition: 'top',
                });
                this.clientLogout();
                return;
              }
            }
          );
        } else {
          isSuccessful = false;
          snackbarOptions = {
            ...snackbarOptions,
            data: {
              message: '¡Ha ocurrido un error al intentar iniciar sesión!',
            },
            duration: 6000,
            panelClass: ['error-snackbar'],
          };
          this.notifyUIWithSnackBar(snackbarOptions, isSuccessful);
          this.clientLogout();
          return;
        }
      }
      const userStatus = loginResponse.data?.login.data.SystemCode;
      if (userStatus?.Entity === 'User' && userStatus.Value === 'Inactive') {
        this.reactivateAccount();
      } else {
        this.notifyUIWithSnackBar(snackbarOptions, isSuccessful);
      }
    });
  };

  notifyUIWithSnackBar = (
    snackbarOptions: MatSnackBarConfig,
    isSuccessful: boolean
  ) => {
    if (isSuccessful) {
      this.snackbar
        .openFromComponent(SnackbarComponent, snackbarOptions)
        .afterOpened()
        .subscribe(() => {
          const navigateTo = this.router.parseUrl(
            this.router.routerState.snapshot.url
          ).queryParams['redirectTo'];
          if (navigateTo) {
            this.router.navigateByUrl(navigateTo);
          } else {
            this.router.navigate(['/']);
          }
        });
    } else {
      this.snackbar.openFromComponent(SnackbarComponent, snackbarOptions);
    }
  };

  setTokenCookie(token: string) {
    const expirationDate: Date = new Date();
    expirationDate.setMinutes(expirationDate.getMinutes() + 59);
    this.cookieService.set('user_token', token, {
      expires: expirationDate,
      sameSite: 'Lax',
    });
  }

  clearTokenCookie() {
    if (this.cookieService.check('user_token')) {
      this.cookieService.delete('user_token');
    }
  }

  handleFirebaseErrors = (error: any) => {
    let errorMessage = '';
    switch (true) {
      case error.code.includes('invalid-login-credentials'):
        errorMessage = '¡Credenciales de acceso inválidos! Intente de nuevo.';
        break;
      case error.code.includes('too-many-requests'):
        errorMessage =
          'Acceso a cuenta deshabilitado temporalmente. ¡Muchos intentos fallidos de inicio de sesión!';
        break;
      case error.code.includes('popup-closed-by-user'):
        errorMessage = 'No se seleccionó ninguna cuenta de Google';
        break;
      default:
        errorMessage = '¡Ha ocurrido un error inesperado!';
        break;
    }

    this.snackbar.openFromComponent(SnackbarComponent, {
      data: {
        message: errorMessage,
      },
      duration: 6000,
      panelClass: ['error-snackbar'],
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  };

  deactivateAccount() {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          question: '¿Está seguro que desea eliminar su cuenta?',
        },
      })
      .afterClosed()
      .subscribe((action) => {
        if (action && action.shouldExecuteAction) {
          this.apollo
            .mutate<DeactivateAccountResponse>({
              mutation: DEACTIVATE_ACCOUNT,
            })
            .subscribe((deactivateResponse) => {
              if (deactivateResponse.data?.deactivateAccount.success) {
                this.router
                  .navigate(['/'], { replaceUrl: true })
                  .finally(() => {
                    this.clientLogout(true);
                  });
              } else {
                this.snackbar.openFromComponent(SnackbarComponent, {
                  data: {
                    message:
                      'Ha ocurrido un error durante la cancelación de su cuenta. ¡Intente darse de baja nuevamente!',
                  },
                  duration: 6000,
                  panelClass: ['error-snackbar'],
                  horizontalPosition: 'end',
                  verticalPosition: 'top',
                });
              }
            });
        }
      });
  }

  reactivateAccount() {
    this.dialog
      .open(ConfirmAccountActivationComponent, {
        data: {
          question:
            '¡Bienvenido de vuelta! Para poder continuar necesitamos reactivar su cuenta. ¿Desea activar su cuenta nuevamente?',
        },
        minHeight: '150px',
      })
      .afterClosed()
      .subscribe((action) => {
        if (action && action.shouldExecuteAction) {
          this.apollo
            .mutate<ReactivateAccountResponse>({
              mutation: REACTIVATE_ACCOUNT,
            })
            .subscribe((reactivateResponse) => {
              if (reactivateResponse.data?.reactivateAccount.success) {
                this.router
                  .navigate(['/'], { replaceUrl: true })
                  .finally(() => {
                    this.snackbar.openFromComponent(SnackbarComponent, {
                      data: {
                        validation: true,
                        message:
                          '¡Su cuenta ha sido reactivada satisfactoriamente!',
                      },
                      duration: 3000,
                      panelClass: ['success-snackbar'],
                      horizontalPosition: 'end',
                      verticalPosition: 'top',
                    });
                  });
              } else {
                this.snackbar
                  .openFromComponent(SnackbarComponent, {
                    data: {
                      message:
                        'Ha ocurrido un error durante la reactivación de su cuenta. ¡Intente iniciar sesión nuevamente!',
                    },
                    duration: 6000,
                    panelClass: ['error-snackbar'],
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                  })
                  .afterOpened()
                  .subscribe(() => {
                    signOut(this.auth);
                    this.clearTokenCookie();
                  });
              }
            });
        } else {
          if (action.visibilityExpired) {
            this.snackbar
              .openFromComponent(SnackbarComponent, {
                data: {
                  warning: true,
                  message:
                    '¡La confirmación de la cuenta ha caducado! Puede intentar iniciar sesión y reactivar su cuenta nuevamente.',
                },
                duration: 2000,
                panelClass: ['warning-snackbar'],
                horizontalPosition: 'end',
                verticalPosition: 'top',
              })
              .afterDismissed()
              .subscribe(() => {
                this.clientLogout(true);
              });
          } else {
            this.snackbar
              .openFromComponent(SnackbarComponent, {
                data: {
                  warning: true,
                  message:
                    'Su cuenta permanecerá desactivada. ¡Si desea activarla nuevamente inicie sesión y confirme que desea activar su cuenta!',
                },
                duration: 6000,
                panelClass: ['warning-snackbar'],
                horizontalPosition: 'end',
                verticalPosition: 'top',
              })
              .afterOpened()
              .subscribe(() => {
                signOut(this.auth);
                this.clearTokenCookie();
              });
          }
        }
      });
  }
}
