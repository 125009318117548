<section class="search-companies-form">
  <mat-form-field appearance="outline" color="accent">
    <input
      matInput
      type="text"
      placeholder="Buscar"
      [formControl]="searchBar"
      (keyup)="onTyping()"
    />
    <button
      *ngIf="searchBar.dirty"
      matSuffix
      mat-icon-button
      matTooltip="Limpiar búsqueda"
      matTooltipPosition="above"
      color="accent"
      (click)="onClearFilters()"
    >
      <mat-icon>backspace</mat-icon>
    </button>
    <button
      matSuffix
      mat-icon-button
      matTooltip="Buscar"
      matTooltipPosition="above"
      color="accent"
      aria-label="Search"
      (click)="onSearchClick()"
      [disabled]="!searchBar.value!.trim()"
    >
      <mat-icon [color]="searchBar.value!.trim() ? 'accent' : 'primary'"
        >search</mat-icon
      >
    </button>
  </mat-form-field>
  <categories-chips-list
    [selectedCategories]="selectedCategories"
  ></categories-chips-list>
  <geolocation-filter
    (userAgentLocation)="onRelayUserAgentLocation($event)"
  ></geolocation-filter>
</section>
