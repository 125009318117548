import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { Apollo, QueryRef } from 'apollo-angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AppointmentsService } from 'src/app/appointments.service';
import { AuthenticationService } from 'src/app/authentication.service';
import {
  GET_PLACE_DETAILS,
  GET_USER,
  getPlaceDetailsVariables,
} from 'src/app/graphql';
import {
  GetPlaceDetailsResponse,
  GetPlaceDetailsVariables,
} from 'src/types/Place';
import { UserResponse } from 'src/types/User';
import { fadeAnimation } from 'src/app/animations';
import { TimeString } from 'src/types/TimeString';

@Component({
  selector: 'time-slot-appointment-info',
  templateUrl: './time-slot-appointment-info.component.html',
  styleUrls: ['./time-slot-appointment-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInComponent', [
      transition(':enter', [
        useAnimation(fadeAnimation, {
          params: { time: '300ms 300ms', start: 0, end: 1 },
        }),
      ]),
    ]),
  ],
})
export class TimeSlotAppointmentInfoComponent implements OnInit, OnDestroy {
  @ViewChild('appointmentInfoWrapper')
  appointmentInfoWrapper?: ElementRef<HTMLElement>;

  currentUser = new BehaviorSubject<{
    email: string;
    displayName: string;
    phoneNumber?: string;
  } | null>(null);
  selectedAppointmentTime: string | null = null;
  estimatedAppointmentTime: TimeString = '00:00';
  specialtyName: string = '';
  specialtyPrice: string = '';

  placeQuery?: QueryRef<GetPlaceDetailsResponse, GetPlaceDetailsVariables>;
  getUserSubscription = new Subscription();

  constructor(
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    public appointmentsService: AppointmentsService
  ) {}

  ngOnInit(): void {
    this.getCurrentUser();

    this.activatedRoute.paramMap.subscribe((params) => {
      this.initializePlaceDetailsQuery(params.get('placeId'));
    });

    this.appointmentsService.currentSpecialty
      .asObservable()
      .subscribe((specialty) => {
        if (specialty) {
          this.estimatedAppointmentTime = new Date(
            Date.parse(specialty.AppointmentTime)
          ).toLocaleTimeString('es-ES', {
            hour: '2-digit',
            minute: '2-digit',
          }) as TimeString;
          this.specialtyName = specialty.Name;
          this.specialtyPrice = specialty.Price;
        }
      });

    this.appointmentsService.selectedHourListener.subscribe(
      (selectedSlotTime) => {
        this.selectedAppointmentTime = selectedSlotTime;
        setTimeout(() => {
          this.appointmentInfoWrapper?.nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 100);
      }
    );
  }

  initializePlaceDetailsQuery(placeId: string | null) {
    if (placeId) {
      this.placeQuery = this.apollo.watchQuery<
        GetPlaceDetailsResponse,
        GetPlaceDetailsVariables
      >({
        query: GET_PLACE_DETAILS,
        variables: getPlaceDetailsVariables(+placeId),
      });
    }
  }

  getCurrentUser() {
    this.authenticationService.isAuthenticated().subscribe((user) => {
      if (!!user) {
        this.getUserSubscription = this.apollo
          .watchQuery<UserResponse>({
            query: GET_USER,
          })
          .valueChanges.subscribe(({ data }) => {
            if (data.getUser.success) {
              const userData = data.getUser.data;
              if (
                userData.FirstName.length > 0 &&
                userData.FirstName.length > 0
              ) {
                this.currentUser.next({
                  email: userData.Email,
                  displayName: `${userData.FirstName} ${userData.LastName}`,
                  phoneNumber: userData.PhoneNumber,
                });
              } else {
                if (user.displayName) {
                  this.currentUser.next({
                    email: user.email!,
                    displayName: user.displayName,
                    phoneNumber: userData.PhoneNumber,
                  });
                }
              }
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.getUserSubscription.unsubscribe();
  }
}
