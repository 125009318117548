import { Component } from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeAnimation } from 'src/app/animations';
import { AppointmentsService } from 'src/app/appointments.service';

@Component({
  selector: 'specialty-description-box',
  templateUrl: './specialty-description-box.component.html',
  styleUrls: ['./specialty-description-box.component.scss'],
  animations: [
    trigger('fadeInComponent', [
      transition(':enter', [
        useAnimation(fadeAnimation, {
          params: { time: '300ms 300ms', start: 0, end: 1 },
        }),
      ]),
    ]),
  ],
})
export class SpecialtyDescriptionBoxComponent {
  constructor(public appointmentsService: AppointmentsService) {}
}
