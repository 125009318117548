import {
  Component,
  HostListener,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/authentication.service';

@Component({
  selector: 'confirm-account-activation',
  templateUrl: './confirm-account-activation.component.html',
  styleUrls: ['./confirm-account-activation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmAccountActivationComponent {
  @HostListener('window:beforeunload', ['$event'])
  onBeforeunloadHandler(event: BeforeUnloadEvent) {
    // logout if user closes or reloads the window when this component is opened:
    this.authenticationService.clientLogout();
  }

  @HostListener('document:visibilitychange', ['$event'])
  onVisibilityChange(event: Event) {
    /* 
    If window is not visible for more than 10 seconds (user changed tab or minimized window) 
    while the component is opened, then cancel the process by logging out and reloading:
    */
    setTimeout(() => {
      if (document.visibilityState === 'hidden') {
        this.dialogRef.close({
          shouldExecuteAction: false,
          visibilityExpired: true,
        });
      }
    }, 10000);
  }

  constructor(
    private authenticationService: AuthenticationService,
    public dialogRef: MatDialogRef<ConfirmAccountActivationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { question: string }
  ) {}

  onConfirmationAction() {
    this.dialogRef.close({ shouldExecuteAction: true });
  }

  closeDialog() {
    this.dialogRef.close({ shouldExecuteAction: false });
  }
}
