import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppointmentsService } from 'src/app/appointments.service';
import { Specialty } from 'src/types/Specialty';
import {
  query,
  stagger,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import { fadeAnimation } from 'src/app/animations';
import { LoadingService } from 'src/app/loading.service';

@Component({
  selector: 'schedule-appointment',
  templateUrl: './schedule-appointment.component.html',
  styleUrls: ['./schedule-appointment.component.scss'],
  animations: [
    trigger('fadeInComponents', [
      transition(':enter', [
        query('h1,h2,.specialties-buttons-list,.calendar-wrapper', [
          useAnimation(fadeAnimation, {
            params: { start: 0, end: 0 },
          }),
          stagger(30, [
            useAnimation(fadeAnimation, {
              params: { time: '300ms 100ms', start: 0, end: 1 },
            }),
          ]),
        ]),
      ]),
    ]),
  ],
})
export class ScheduleAppointmentComponent implements OnInit, OnDestroy {
  @ViewChild('calendarWrapper') calendarWrapper?: ElementRef<HTMLElement>;
  specialties!: Specialty[];
  specialtiesSubscription = new Subscription();

  constructor(
    public appointmentsService: AppointmentsService,
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadingService.setLoading(true);
    this.activatedRoute.params.subscribe((routeParams) => {
      this.appointmentsService.placeId = routeParams['placeId'];
      this.activatedRoute.queryParamMap.subscribe((queryParams) => {
        const pendingAppointmentToSchedule = queryParams.get(
          'pending-appointment'
        );
        const specialtyParam = queryParams.get('specialty');
        const appointmentParam = queryParams.get('appointment');
        if (pendingAppointmentToSchedule) {
          this.appointmentsService.manageAppointmentScheduling();
        } else {
          this.appointmentsService.selectedSpecialtyIdByParam = specialtyParam
            ? +specialtyParam
            : null;
          this.appointmentsService.appointmentIdByParam = appointmentParam
            ? +appointmentParam
            : null;
        }

        this.specialtiesSubscription = this.appointmentsService
          .specialtiesQuery()
          .valueChanges.subscribe((specialtiesResponse) => {
            this.loadingService.setLoading(specialtiesResponse.loading);
            if (specialtiesResponse.data.specialties.length === 0) {
              // If there aren't any specialties available for this place, redirect to home
              this.router.navigate(['']);
            } else {
              this.specialties = specialtiesResponse.data.specialties;

              if (this.appointmentsService.selectedSpecialtyIdByParam) {
                this.specialties.map((specialty) => {
                  if (
                    specialty.Id ===
                    this.appointmentsService.selectedSpecialtyIdByParam
                  ) {
                    this.appointmentsService.currentSpecialty.next(specialty);
                  }
                });
              }
            }
          });
      });
    });
  }

  onSelectSpecialty(specialty: Specialty) {
    if (specialty.Id !== this.appointmentsService.selectedSpecialtyIdByParam) {
      // Notify selected date as null to cleanup the selected date on the calendar:
      this.appointmentsService.onDateSelected(null);
      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
        queryParams: { specialty: specialty.Id },
        replaceUrl: true,
      });
      setTimeout(() => {
        this.calendarWrapper?.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 300);
    }
  }

  ngOnDestroy(): void {
    this.appointmentsService.resetUIToInitialState();
    this.specialtiesSubscription.unsubscribe();
  }
}
