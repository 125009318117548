import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { inject } from '@angular/core';
import { filter, map } from 'rxjs';

export const authScreensGuard: CanActivateFn = (route, state) => {
  const authenticationService: AuthenticationService = inject(
    AuthenticationService
  );
  const router: Router = inject(Router);

  return authenticationService.isAuthenticated().pipe(
    filter((user) => user !== undefined),
    map((user) => {
      if (!!user) {
        return router.createUrlTree(['/']);
      }
      return true;
    })
  );
};
