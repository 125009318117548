import { NgModule, CUSTOM_ELEMENTS_SCHEMA, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';

import { AppComponent } from './app.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import {
  provideAnalytics,
  getAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';

import { provideAuth, getAuth } from '@angular/fire/auth';
import { providePerformance, getPerformance } from '@angular/fire/performance';
import { ClientRegistrationComponent } from './pages/Auth/RegisterPage/client-registration/client-registration.component';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { RestorePasswordComponent } from './pages/Auth/RestorePasswordPage/restore-password/restore-password.component';
import { ClientLoginComponent } from './pages/Auth/LoginPage/client-login/client-login.component';
import { ChangePasswordComponent } from './pages/Auth/ChangePasswordPage/change-password/change-password.component';
import { AppointmentDetailsComponent } from './pages/Appointments/appointment-details/appointment-details.component';

import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { CookieService } from 'ngx-cookie-service';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { HomeComponent } from './pages/Home/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { AppRoutingModule } from './app-routing.module';
import { AppointmentsComponent } from './pages/Appointments/appointments/appointments.component';
import { ProfileComponent } from './pages/Profile/profile/profile.component';
import { MenuComponent } from './components/sidenav/menu/menu.component';
import { MenuItemsComponent } from './components/sidenav/menu/menu-item/menu-items.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ContentDialogComponent } from './components/content-dialog/content-dialog.component';
import { GraphQLModule } from './graphql.module';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { SearchCompaniesComponent } from './components/search-companies/search-companies.component';
import { CardsGridComponent } from './components/cards-grid/cards-grid.component';
import { IntersectionObserverDirective } from './intersection-observer.directive';
import { CompanyCardComponent } from './components/cards-grid/company-card/company-card.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { UpcomingAppointmentsComponent } from './components/upcoming-appointments/upcoming-appointments.component';
import { TimeFromStringPipe } from './time-from-string.pipe';
import { ProfilePictureComponent } from './components/profile-picture/profile-picture.component';
import { GeolocationFilterComponent } from './components/search-companies/geolocation-filter/geolocation-filter.component';
import { CompanyDetailComponent } from './pages/Company/company-detail/company-detail.component';
import { FilterArrayPipe } from './filter-array.pipe';
import { CompanyInfoCardComponent } from './components/company-info-card/company-info-card.component';
import { HighlightSelectedDirective } from './highlight-selected.directive';
import { CompanyImageDialogComponent } from './components/company-info-card/company-image-dialog/company-image-dialog.component';
import { SpecialtyCardComponent } from './components/specialty-card/specialty-card.component';
import { ContrastTextColorDirective } from './contrast-text-color.directive';
import { ScheduleAppointmentComponent } from './pages/ScheduleAppointment/schedule-appointment/schedule-appointment.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { NotFoundComponent } from './pages/404/not-found/not-found.component';
import { ErrorScreensNavbarComponent } from './components/error-screens-navbar/error-screens-navbar.component';
import { SiteInMaintenanceComponent } from './pages/Maintenance/site-in-maintenance/site-in-maintenance.component';
import { ScheduleTimeSlotSelectorComponent } from './components/schedule-time-slot-selector/schedule-time-slot-selector.component';
import { TimeSlotAppointmentInfoComponent } from './components/schedule-time-slot-selector/time-slot-appointment-info/time-slot-appointment-info.component';
import { ScheduleAppointmentModalComponent } from './components/schedule-time-slot-selector/schedule-appointment-modal/schedule-appointment-modal.component';
import { DarkenBorderColorDirective } from './darken-border-color.directive';
import { RestorePasswordFormComponent } from './pages/Auth/RestorePasswordPage/restore-password-form/restore-password-form.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { SpecialtyDescriptionBoxComponent } from './components/specialty-description-box/specialty-description-box.component';
import { ConfirmAccountActivationComponent } from './components/confirm-account-activation/confirm-account-activation.component';
import { ImageComponent } from './components/image/image.component';
import { LocationButtonsComponent } from './components/location-buttons/location-buttons.component';
import { AppointmentHistoryComponent } from './pages/Appointments/appointment-history/appointment-history.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { AppointmentHistoryCardComponent } from './components/appointment-history-card/appointment-history-card.component';
import { RadioFilterComponent } from './components/radio-filter/radio-filter.component';
import { RateAppointmentModalComponent } from './components/rate-appointment-modal/rate-appointment-modal.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { AppointmentHistoryCommentModalComponent } from './components/appointment-history-comment-modal/appointment-history-comment-modal.component';
import { DetectLinksDirective } from './detect-links.directive';
import { FeedbackComponent } from './pages/Feedback/feedback/feedback.component';
import { RegisterCompanyButtonComponent } from './components/register-company-button/register-company-button.component';
import { LoadingComponent } from './components/loading/loading.component';
import { TimeSuffixPipe } from './time-suffix.pipe';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SwUpdateModalComponent } from './components/sw-update-modal/sw-update-modal.component';
import { SpecialtyCardSwiperComponent } from './components/specialty-card/specialty-card-swiper/specialty-card-swiper.component';
import { CategoriesChipsListComponent } from './components/categories-chips-list/categories-chips-list.component';
import { UserAgentModalComponent } from './components/user-agent-modal/user-agent-modal.component';

const materialComponents = [
  MatIconModule,
  MatInputModule,
  MatRadioModule,
  MatButtonModule,
  MatToolbarModule,
  MatSidenavModule,
  MatCardModule,
  MatChipsModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatRippleModule,
  MatSnackBarModule,
  MatDialogModule,
  MatStepperModule,
  MatTabsModule,
  MatMenuModule,
];

const customStandaloneComponents = [ContentDialogComponent, SnackbarComponent];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    SidenavComponent,
    AppointmentsComponent,
    ProfileComponent,
    MenuComponent,
    MenuItemsComponent,
    CarouselComponent,
    ClientRegistrationComponent,
    AuthLayoutComponent,
    ClientLoginComponent,
    RestorePasswordComponent,
    ChangePasswordComponent,
    SearchCompaniesComponent,
    CardsGridComponent,
    IntersectionObserverDirective,
    CompanyCardComponent,
    UpcomingAppointmentsComponent,
    TimeFromStringPipe,
    ProfilePictureComponent,
    CompanyDetailComponent,
    FilterArrayPipe,
    CompanyInfoCardComponent,
    HighlightSelectedDirective,
    CompanyImageDialogComponent,
    SpecialtyCardComponent,
    GeolocationFilterComponent,
    ContrastTextColorDirective,
    AppointmentDetailsComponent,
    ScheduleAppointmentComponent,
    CalendarComponent,
    NotFoundComponent,
    ErrorScreensNavbarComponent,
    SiteInMaintenanceComponent,
    ScheduleTimeSlotSelectorComponent,
    TimeSlotAppointmentInfoComponent,
    ScheduleAppointmentModalComponent,
    DarkenBorderColorDirective,
    RestorePasswordFormComponent,
    ConfirmationDialogComponent,
    SpecialtyDescriptionBoxComponent,
    ConfirmAccountActivationComponent,
    ImageComponent,
    LocationButtonsComponent,
    AppointmentHistoryComponent,
    PaginatorComponent,
    AppointmentHistoryCardComponent,
    RadioFilterComponent,
    RateAppointmentModalComponent,
    StarRatingComponent,
    AppointmentHistoryCommentModalComponent,
    DetectLinksDirective,
    FeedbackComponent,
    RegisterCompanyButtonComponent,
    LoadingComponent,
    TimeSuffixPipe,
    SwUpdateModalComponent,
    SpecialtyCardSwiperComponent,
    CategoriesChipsListComponent,
    UserAgentModalComponent,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    materialComponents,
    customStandaloneComponents,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    AppRoutingModule,
    GraphQLModule,
    FormsModule,
    FullCalendarModule,
    NgxMatIntlTelInputComponent,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    provideAnalytics(() => getAnalytics()),
    providePerformance(() => getPerformance()),
    ScreenTrackingService,
    UserTrackingService,
    provideNgxMask(),
    CookieService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
